import React, { useEffect, useState } from 'react'
import firebase from 'gatsby-plugin-firebase'
import 'firebase/database'
import incrementViews from '../lib/increment-views'
import { Eye } from '@styled-icons/entypo/'

const ViewCounter = ({ id }) => {
  const [viewCount, setViewCount] = useState('')

  useEffect(() => {
    // 1 is displayed for a split second and then the correct count
    // This is a workaround
    const onViews = (newViews) => {
      setViewCount(newViews.val() === 1 ? 0 : newViews.val())
    }

    incrementViews(id)

    firebase.database().ref(`/views`).child(id).on(`value`, onViews)

    return () => {
      if (firebase.database()) {
        firebase.database().ref(`/views`).child(id).off(`value`, onViews)
      }
    }
  }, [id])

  return (
    <div>
      <span>|</span> {viewCount ? viewCount : `---`} <Eye size="1.5em" /> views
    </div>
  )
}

export default ViewCounter
