import React from 'react'

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share'
import {
  Twitter,
  Whatsapp,
  FacebookCircle,
  LinkedinBox
} from '@styled-icons/remix-line'

const ShareButtons = ({ title, url, twitterHandle, tags }) => {
  return (
    <div>
      <span>Share: </span>
      <FacebookShareButton url={url}>
        <FacebookCircle size="1.7em" color="#1873eb" />
      </FacebookShareButton>

      <TwitterShareButton url={url} title={title}>
        <Twitter size="1.7em" color="#1c9cea" />
      </TwitterShareButton>

      <LinkedinShareButton url={url}>
        <LinkedinBox size="1.7em" color="#0173b1" />
      </LinkedinShareButton>

      <WhatsappShareButton url={url} title={title}>
        <Whatsapp size="1.7em" color="#23cc63" />
      </WhatsappShareButton>
    </div>
  )
}
export default ShareButtons
