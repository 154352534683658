import React, { Fragment } from 'react'
import _get from 'lodash/get'
import { Link, graphql } from 'gatsby'
import { DiscussionEmbed } from 'disqus-react'
import Helmet from 'react-helmet'
import ViewCounter from '../components/ViewCounter'

import Content from '../components/Content'
import Layout from '../components/Layout'
import Image from '../components/Image'
import ShareButtons from '../components/ShareButtons'
import Fade from 'react-reveal/Fade'
import './SinglePost.css'
import { ChevronLeft, ChevronRight } from '@styled-icons/boxicons-regular/'

export const SinglePostTemplate = ({
  title,
  featuredImage,
  date,
  body,
  url,
  slug,
  nextPostURL,
  prevPostURL,
  nextPostTitle,
  prevPostTitle,
  disqusConfig,
  categories = []
}) => (
  <main>
    <Helmet>
      <title>{title}</title>
      <meta name="twitter:image" content={featuredImage} />
      <meta property="og:image:secure_url" content={featuredImage} />
      <meta property="og:image" content={featuredImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
    </Helmet>
    <article
      className="SinglePost section light"
      itemScope
      itemType="http://schema.org/BlogPosting"
    >
      <Image
        background
        className="SinglePost--BackgroundImage"
        src={featuredImage}
        alt={title}
      />
      <div className="container skinny" style={{ width: '98%' }}>
        <Link className="SinglePost--BackButton" to="/blog/">
          <ChevronLeft size="2em" /> BACK
        </Link>
        <Fade left>
          <div className="SinglePost--Content relative">
            {title && (
              <h1 className="SinglePost--Title" itemProp="title">
                {title}
              </h1>
            )}

            <div
              className="SinglePost--Meta"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {date && (
                <time
                  className="SinglePost--Meta--Date"
                  itemProp="dateCreated pubdate datePublished"
                  date={date}
                >
                  {date}
                </time>
              )}
              {categories && (
                <Fragment>
                  <span>|</span>
                  {categories.map((cat, index) => (
                    <span
                      key={cat.category}
                      className="SinglePost--Meta--Category"
                    >
                      {cat.category}
                      {/* Add a comma on all but last category */}
                      {index !== categories.length - 1 ? ',' : ''}
                    </span>
                  ))}
                </Fragment>
              )}
              <span>|</span>
              <ShareButtons
                title={'Read this blog by Ilham Fadhil on ' + title}
                url={url}
              />
              {slug && <ViewCounter id={slug} />}
            </div>
            <div className="SinglePost--InnerContent">
              <Content source={body} />
            </div>
            <div className="SinglePost--Pagination">
              {prevPostURL && (
                <Link
                  className="SinglePost--Pagination--Link prev"
                  to={prevPostURL}
                >
                  <ChevronLeft size="2em" />
                  Previous Post <br />
                  {prevPostTitle}
                </Link>
              )}
              {nextPostURL && (
                <Link
                  className="SinglePost--Pagination--Link next"
                  to={nextPostURL}
                  style={{ textAlign: 'right' }}
                >
                  Next Post
                  <ChevronRight size="2em" />
                  <br />
                  {nextPostTitle}
                </Link>
              )}
            </div>
            <div className="SinglePost--Disqus">
              {disqusConfig && (
                <DiscussionEmbed
                  shortname="ilhamfadhil-com"
                  config={disqusConfig}
                />
              )}
            </div>
          </div>
        </Fade>
      </div>
    </article>
  </main>
)

// Export Default SinglePost for front-end
const SinglePost = ({ data: { post, allPosts } }) => {
  const thisEdge = allPosts.edges.find((edge) => edge.node.id === post.id)
  let disqusConfigs = {
    identifier: post.frontmatter.id,
    title: post.frontmatter.title
  }
  return (
    <Layout
      meta={post.frontmatter.meta || false}
      title={post.frontmatter.title || false}
    >
      <SinglePostTemplate
        {...post}
        {...post.frontmatter}
        body={post.html}
        slug={post.fields.slug}
        disqusConfig={disqusConfigs}
        nextPostURL={_get(thisEdge, 'next.fields.slug')}
        nextPostTitle={_get(thisEdge, 'next.frontmatter.title')}
        prevPostURL={_get(thisEdge, 'previous.fields.slug')}
        prevPostTitle={_get(thisEdge, 'previous.frontmatter.title')}
        url={'https://ilhamfadhil.com' + post.fields.slug}
      />
    </Layout>
  )
}

export default SinglePost

export const pageQuery = graphql`
  ## Query for SinglePost data
  ## Use GraphQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query SinglePost($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      id
      fields {
        slug
      }
      frontmatter {
        title
        featuredImage
        template
        subtitle
        date(formatString: "MMMM Do, YYYY")
        categories {
          category
        }
      }
    }

    allPosts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "posts" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
        }
        next {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
        previous {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
